html,
body {
  height: 100%;
}

html {
  overflow-y: scroll;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  flex: 1 1 auto;
  margin-bottom: 30px;
  background-color: #fafafa;
}

.brand {
  color: #1d477a;
  font-weight: 500;
}

.bolder {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

#__react-alert__ {
  & > div {
    margin-top: 80px;
  }
}
