.navbar {
  background-color: #1d477a !important;

  .navbar-brand {
    font-size: 22px;
    display: flex;
    padding: 0;

    .title {
      margin-top: 12px;

      .sub {
        font-style: italic;
        font-weight: 300;
      }
    }

    .logo {
      height: 52px;
    }
  }

  @media (min-width: 576px) {
    .navbar-nav {
      margin-top: 8px;
    }
  }
}
