.auth {
  background-color: #1d477a;

  .row {
    margin-top: 20vh;

    .card {
      background-color: #85b1e7;
    }
  }
}
